<div class="row" style="width: 100%;margin: auto;">
  <div class="col-xl-3 col-xs-3" style="padding-left: 0;">
    <app-menu></app-menu>
  </div>
  <div class="col-xl-9 col-xs-9" style="margin-top: 20px;">
    <div class="page-title" *ngIf="!isAdmin">
      <app-credit-disponible></app-credit-disponible>
    </div>
    <div class="page-title">
      <h3 class="breadcrumb-header"></h3>
    </div>
    <div id="main-wrapper">
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-white">
            <div class="panel-body">
              <div class="table-responsive">
                <table class="display table dataTable" style="border: 3px solid black; background-color: #F7F9FA; border-radius: 20px; width: 600px; max-width: 99%;">
                  <tr>
                    <td style="text-align: center; font-size: 24px;">
                      <strong>Modifier ma campagne</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">
                      <label>Référence de la campagne</label>
                      <input class="form-control" [(ngModel)]="campagne.nomCampagne" placeholder="Référence de la campagne..." />
                      <div style="color: red; font-size: 15px;" *ngIf="error.reference">
                        Merci de saisir la référence de votre campagne
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: center;">
                      <label>Message à envoyer</label>
                      <textarea class="form-control" rows="8" [(ngModel)]="campagne.message" placeholder="Message..."></textarea>
                      <div style="color: red; font-size: 15px;" *ngIf="error.message">
                        Merci de saisir votre message
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: left;">
                      <button class="btn btn-success" style="width: 100%;" (click)="confirmationModificationCampagne()">Valider les modifications</button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
