import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { MenuComponent } from './components/menu/menu.component';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import { UsersComponent } from './pages/users/users.component';
import { SmsComponent } from './pages/sms/sms.component';
import { PortsComponent } from './pages/ports/ports.component';
import { ParametragesComponent } from './pages/parametrages/parametrages.component';
import { ErreurComponent } from './pages/erreur/erreur.component';
import {GlobalService} from "./services/global.service";
import {AuthGuard} from "./services/auth-guard.service";
import {HashLocationStrategy, LocationStrategy} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {RouterModule, Routes} from "@angular/router";
import {HttpClientModule} from "@angular/common/http";
import {LoginComponent} from './pages/login/login.component';
import { CampagnesComponent } from './pages/campagnes/campagnes.component';
import { SuiviComponent } from './pages/suivi/suivi.component';
import { CreditDisponibleComponent } from './pages/credit-disponible/credit-disponible.component';
import { ModifierCampagneComponent } from './pages/modifier-campagne/modifier-campagne.component';
import { RechargeComponent } from './pages/recharge/recharge.component';
import { StopsComponent } from './pages/stops/stops.component';
import { ArchiveComponent } from './pages/archive/archive.component';
import { SimManagementComponent } from './pages/sim-management/sim-management.component';
import { LogsComponent } from './pages/logs/logs.component';

const appRoutes: Routes =
  [{
    path: '',
    component: LoginComponent
  }, {
    path: 'login',
    component: LoginComponent
  }, {
    path: 'ports',
    component: PortsComponent
  }, {
    path: 'parametrage',
    component: ParametragesComponent
  }, {
    path: 'sms',
    component: SmsComponent
  }, {
    path: 'users',
    component: UsersComponent
  }, {
    path: 'campagne',
    component: CampagnesComponent
  }, {
    path: 'stops',
    component: StopsComponent
  }, {
    path: 'modifier-campagne/:idCampagne',
    component: ModifierCampagneComponent
  }, {
    path: 'suivi',
    component: SuiviComponent
  }, {
    path: 'archive',
    component: ArchiveComponent
  }, {
    path: 'rechargement',
    component: RechargeComponent
  }, {
    path: 'sim-management',
    component: SimManagementComponent
  }, {
    path: 'logs',
    component: LogsComponent
  }, {
    path: '**',
    component: ErreurComponent
  }
  ];

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    UsersComponent,
    SmsComponent,
    PortsComponent,
    ParametragesComponent,
    ErreurComponent,
    LoginComponent,
    CampagnesComponent,
    SuiviComponent,
    CreditDisponibleComponent,
    ModifierCampagneComponent,
    RechargeComponent,
    StopsComponent,
    ArchiveComponent,
    LogsComponent,
    SimManagementComponent,
    LogsComponent
  ],
    imports: [
      BrowserModule,
      HttpClientModule,
      RouterModule.forRoot(appRoutes, { useHash: false }),
      FontAwesomeModule,
      FormsModule
    ],
  providers: [
    GlobalService,
    AuthGuard,
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
