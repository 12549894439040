<div>
  <div class="logo_holder centrer_logo menu_site">
    <div class="page-sidebar" style="padding-top: 150px;">
      <div class="page-sidebar-inner">
        <div class="page-sidebar-menu">
          <ul class="accordion-menu">
            <li [ngClass]="{'active-page': url === 'campagne'}">
              <a routerLink="/campagne">
                <span>Nouvelle campagne</span>
              </a>
            </li>
            <li [ngClass]="{'active-page': url === 'suivi'}">
              <a routerLink="/suivi">
                <span>Suivi campagnes</span>
              </a>
            </li>
            <li [ngClass]="{'active-page': url === 'archive'}">
              <a routerLink="/archive">
                <span>Archives</span>
              </a>
            </li>
            <li [ngClass]="{'active-page': url === 'ports'}">
              <a routerLink="/ports" *ngIf="isAdmin">
                <span>Ports</span>
              </a>
            </li>
            <li [ngClass]="{'active-page': url === 'parametrage'}">
              <a routerLink="/parametrage" *ngIf="isAdmin">
                <span>Paramétrage</span>
              </a>
            </li>
            <li [ngClass]="{'active-page': url === 'stops'}">
              <a routerLink="/stops">
                <span>Blacklist</span>
              </a>
            </li>
            <li [ngClass]="{'active-page': url === 'rechargement'}">
              <a routerLink="/rechargement" *ngIf="isAdmin">
                <span>Rechargement</span>
              </a>
            </li>
            <li [ngClass]="{'active-page': url === 'sim-management'}">
              <a routerLink="/sim-management" *ngIf="isAdmin">
                <span>Sim Management</span>
              </a>
            </li>
            <li [ngClass]="{'active-page': url === 'users'}">
              <a routerLink="/users" *ngIf="isAdmin">
                <span>Users</span>
              </a>
            </li>
            <li [ngClass]="{'active-page': url === 'logs'}">
              <a routerLink="/logs" *ngIf="isAdmin">
                <span>Logs</span>
              </a>
            </li>
            <li>
              <a (click)="deconnexion()">
                <span>Déconnexion</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="hamburger_holder menu_mobile" style="top: 30px;">
    <fa-icon (click)="menu()" class="icon_menu" [icon]="icon"></fa-icon>
  </div>
  <div class="menu_holder" [ngClass]="open? 'ouvert': 'fermer'">
    <div class="inner_holder">
      <div class="text-logo">
        <h3 class="logo_name">Mon espace</h3>
      </div>
      <nav id="site-navigation" class="main-navigation">
        <div class="menu-primary-menu-container">
          <ul class="primary-menu">
            <li [ngClass]="{'active-page': url === 'campagne'}">
              <a routerLink="/campagne" style="font-size: 12px !important;">
                <span>Nouvelle campagne</span>
              </a>
            </li>
            <li [ngClass]="{'active-page': url === 'suivi'}">
              <a routerLink="/suivi" style="font-size: 12px !important;">
                <span>Suivi campagnes</span>
              </a>
            </li>
            <li [ngClass]="{'active-page': url === 'archive'}">
              <a routerLink="/archive" style="font-size: 12px !important;">
                <span>Archives</span>
              </a>
            </li>
            <li [ngClass]="{'active-page': url === 'ports'}" *ngIf="isAdmin">
              <a routerLink="/ports" style="font-size: 12px !important;">
                <span>Ports</span>
              </a>
            </li>
            <li [ngClass]="{'active-page': url === 'parametrage'}" *ngIf="isAdmin">
              <a routerLink="/parametrage" style="font-size: 12px !important;">
                <span>Paramétrage</span>
              </a>
            </li>
            <li [ngClass]="{'active-page': url === 'rechargement'}" *ngIf="isAdmin">
              <a routerLink="/rechargement" style="font-size: 12px !important;">
                <span>Rechargement</span>
              </a>
            </li>
            <li [ngClass]="{'active-page': url === 'sim-management'}">
              <a routerLink="/sim-management" style="font-size: 12px !important;" *ngIf="isAdmin">
                <span>Sim Management</span>
              </a>
            </li>
            <li [ngClass]="{'active-page': url === 'users'}" *ngIf="isAdmin">
              <a routerLink="/users" style="font-size: 12px !important;">
                <span>Users</span>
              </a>
            </li>
            <li [ngClass]="{'active-page': url === 'logs'}" *ngIf="isAdmin">
              <a routerLink="/logs" style="font-size: 12px !important;">
                <span>Logs</span>
              </a>
            </li>
            <li>
              <a (click)="deconnexion()" style="font-size: 12px !important;">
                <span>Déconnexion</span>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</div>
