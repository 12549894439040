import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {GlobalService} from "../../services/global.service";

@Component({
  selector: 'app-parametrages',
  templateUrl: './parametrages.component.html',
  styleUrls: ['./parametrages.component.css']
})
export class ParametragesComponent implements OnInit {
  parametrages: any = [];
  constructor(private route: ActivatedRoute,
              private httpClient: HttpClient,
              private globalService: GlobalService,
              private router: Router) { }

  ngOnInit(): void {
    if (localStorage.getItem("isAdmin") != "true") {
      this.router.navigate(['/suivi']);
    }
    this.getParametrages();
  }
  update(): void {
    this.httpClient
      .post<any>(this.globalService.url + '/parametrages', {parametres: this.parametrages}, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          alert('Modifié');
          this.getParametrages();
        }
      }, () => {
      });
  }
  getParametrages(): void {
    this.httpClient
      .get<any>(this.globalService.url + '/parametrages', {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          this.parametrages = response.parametres;
        }
      }, () => {
      });
  }
  debloquerVerrou(): void {
    this.httpClient
      .get<any>(this.globalService.url + '/verrou', {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          alert('Verrou débloqué');
        }
      }, () => {
      });
  }
}
