<div class="row" style="width: 100%;margin: auto;">
  <div class="col-xl-3 col-xs-3">
    <app-menu></app-menu>
  </div>
  <div class="col-xl-9 col-xs-9" style="margin-top: 20px;">
    <div class="page-title">
      <h3 class="breadcrumb-header">Gestion des ports</h3>
    </div>
    <div id="main-wrapper">
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-white">
            <div class="panel-body">
              <div class="table-responsive">
                <table class="display table dataTable">
                  <tr>
                    <td colspan="12">
                      <button class="btn btn-success" (click)="test()">Test Manuel des ports</button>
                      {{status}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Numéro
                    </td>
                    <td style="width: 80px;">
                      Vérification automatique
                    </td>
                    <td style="width: 80px;">
                      Est fonctionnel
                    </td>
                    <td style="width: 80px;">
                      A modifier
                    </td>
                    <td>
                      Ouvert / fermé
                    </td>
                    <td>
                      Type d'envoi
                    </td>
                    <td>
                      Limite SMS
                    </td>
                    <td>
                      Limite MMS
                    </td>
                    <td>
                      Quota SMS
                    </td>
                    <td>
                      Pile d'envoi
                    </td>
                    <td>
                      Affectation
                    </td>
                    <td>
                      Heure ouverture
                    </td>
                  </tr>
                  <tr>
                    <td colspan="12">
                      <button class="form-control btn-success" (click)="update()">Sauvegarder</button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Général
                    </td>
                    <td style="width: 80px;">
                      <input type="checkbox" (click)="changeTestAutomatiqueGeneral()" [checked]="testAutomatique == 'y'" />
                    </td>
                    <td style="width: 80px;">
                      <input type="checkbox" (click)="changeUseGeneral()" [checked]="use == 'y'" />
                    </td>
                    <td style="width: 80px;">
                      <input type="checkbox" (click)="changeUpdateGeneral()" [checked]="updateCheckbox == 'y'" />
                    </td>
                    <td>
                      <select (change)="changeValue('etat')" [(ngModel)]="general" class="form-control">
                        <option value="">Choisir un état...</option>
                        <option value="o">Ouvert</option>
                        <option value="f">Fermé</option>
                      </select>
                    </td>
                    <td>
                      <select (change)="changeValue('type')" [(ngModel)]="type" class="form-control">
                        <option value="s">SMS</option>
                        <option value="m">MMS</option>
                      </select>
                    </td>
                    <td>
                      <input class="form-control" type="number" (input)="changeLimiteSmsGeneral()" [(ngModel)]="limiteSms" />
                    </td>
                    <td>
                      <input class="form-control" type="number" (input)="changeLimiteMmsGeneral()" [(ngModel)]="limiteMms" />
                    </td>
                    <td>
                      <button class="btn btn-success" (click)="remiseAZero(-1, 'sms')">Remise à 0</button>
                    </td>
                    <td>
                      <button class="btn btn-success" (click)="remiseAZero(-1, 'pile')">Remise à 0</button>
                    </td>
                    <td>
                      <select (change)="changeValue('user')" [(ngModel)]="idUser" class="form-control">
                        <option value="">Aucun</option>
                        <option value="-1">Alerte SMS</option>
                        <option *ngFor="let u of users" [value]="u._id">
                          <span *ngIf="u.typeAccount == 'smpp'">SMPP</span> {{u.identifiant}}
                        </option>
                      </select>
                    </td>
                    <td>
                      <input (change)="changeHeureOuvertureGeneral()" [(ngModel)]="heureOuverture" type="datetime-local" class="form-control" />
                    </td>
                  </tr>
                  <tr *ngFor="let p of ports">
                    <td *ngIf="p.isOpen == 'o' && p.type == 's'" style="background-color: #5D7052;color: white;">
                      {{p.slotActuel}}
                      <span *ngFor="let sfr of indicationSfrs">
                        <span *ngIf="sfr.port == p.number" style="color: red;">INFO SFR</span>
                      </span>
                    </td>
                    <td *ngIf="p.isOpen == 'o' && p.type == 'm'" style="background-color: #A4BD01;color: white;">
                      {{p.slotActuel}}
                      <span *ngFor="let sfr of indicationSfrs">
                        <span *ngIf="sfr.port == p.number" style="color: red;">INFO SFR</span>
                      </span>
                    </td>
                    <td *ngIf="p.isOpen == 'f' && p.type == 's'" style="background-color: #C23028;color: white;">
                      {{p.slotActuel}}
                      <span *ngFor="let sfr of indicationSfrs">
                        <span *ngIf="sfr.port == p.number" style="color: #A4BD01;">INFO SFR</span>
                      </span>
                    </td>
                    <td *ngIf="p.isOpen == 'f' && p.type == 'm'" style="background-color: #FC4E00; color: white;">
                      {{p.slotActuel}}
                      <span *ngFor="let sfr of indicationSfrs">
                        <span *ngIf="sfr.port == p.number" style="color: #A4BD01;">INFO SFR</span>
                      </span>
                    </td>
                    <td>
                      <input type="checkbox" [checked]="p.testAutomatique == 'y'" (click)="changeTestAutomatique(p._id)" />
                    </td>
                    <td>
                      <input type="checkbox" [checked]="p.use == 'y'" (click)="changeUse(p._id)" />
                    </td>
                    <td>
                      <input type="checkbox" [checked]="p.updateCheckbox == 'y'" (click)="changeUpdate(p._id)" />
                    </td>
                    <td>
                      <select [(ngModel)]="p.isOpen" class="form-control">
                        <option value="o" [selected]="p.isOpen == 'o'">Ouvert</option>
                        <option value="f" [selected]="p.isOpen == 'f'">Fermé</option>
                      </select>
                    </td>
                    <td>
                      <select [(ngModel)]="p.type" class="form-control">
                        <option value="s" [selected]="p.type == 's'">SMS</option>
                        <option value="m" [selected]="p.type == 'm'">MMS</option>
                      </select>
                    </td>
                    <td>
                      <input class="form-control" type="number" [(ngModel)]="p.limiteSms" />
                    </td>
                    <td>
                      <input class="form-control" type="number" [(ngModel)]="p.limiteMms" />
                    </td>
                    <td style="text-align: center;">
                      {{p.quotaSms}}<br />
                      <button class="btn btn-success" (click)="remiseAZero(p.number, 'sms')">Remise à 0</button>
                    </td>
                    <td style="text-align: center;">
                      {{p.notReallySend}}<br />
                      <button class="btn btn-success" (click)="remiseAZero(p.number, 'pile')">Remise à 0</button>
                    </td>
                    <td>
                      <select [(ngModel)]="p.idUser" class="form-control">
                        <option value="">Aucune</option>
                        <option value="-1">Alerte SMS</option>
                        <option *ngFor="let u of users" [value]="u._id">
                          <span *ngIf="u.typeAccount == 'smpp'">SMPP</span> {{u.identifiant}}
                        </option>
                      </select>
                    </td>
                    <td>
                      <input [(ngModel)]="p.heureOuverture" type="datetime-local" class="form-control" />
                    </td>
                  </tr>
                  <tr>
                    <td colspan="12">
                      <button class="form-control btn-success" (click)="update()">Sauvegarder</button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
