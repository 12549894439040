<div class="row" style="width: 100%;margin: auto;">
  <div class="col-xl-3 col-xs-3">
    <app-menu></app-menu>
  </div>
  <div class="col-xl-9 col-xs-9" style="margin-top: 20px;">
    <div class="page-title">
      <h3 class="breadcrumb-header">Gestion des sms / mms</h3>
    </div>
    <div id="main-wrapper">
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-white">
            <div class="panel-body">
              <div class="table-responsive">
                <table class="display table dataTable">
                  <tr>
                    <td>
                      Total
                    </td>
                    <td>
                      En attente
                    </td>
                    <td>
                      Envoyé
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{nums.attente + nums.fait}}
                    </td>
                    <td>
                      {{nums.attente}}
                    </td>
                    <td>
                      {{nums.fait}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                    </td>
                    <td>
                      <button class="form-control btn btn-success" (click)="exporter(0)">Exporter</button>
                    </td>
                    <td>
                      <button class="form-control btn btn-success" (click)="exporter(1)">Exporter</button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                    </td>
                    <td>
                      <button class="form-control btn btn-danger" (click)="supprimer(0)">Supprimer</button>
                    </td>
                    <td>
                      <button class="form-control btn btn-danger" (click)="supprimer(1)">Supprimer</button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
