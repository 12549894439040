<section class="flat-why-choose style1 bg-browse" style="padding-top: 200px; padding-bottom: 300px;">
  <div class="container">
    <div class="row">
      <div class="offset-md-3 col-md-6">
        <div class="top-title center">
          <label>Identifiant *</label>
          <input type="text" id="identifiant" class="form-control" placeholder="Identifiant" [(ngModel)]="login.identifiant">
        </div>
      </div>
      <div class="offset-md-3 col-md-6">
        <div class="top-title center">
          <label>Mot de passe *</label>
          <input type="password" id="password" class="form-control" placeholder="Mot de passe" [(ngModel)]="login.password">
        </div>
      </div>
      <div class="offset-md-3 col-md-6" [attr.disabled]="!tentativeConnexion">
        <div class="top-title center">
          <p *ngIf="error !== ''" class="error">
            {{error}}
          </p>
        </div>
      </div>
      <div class="offset-md-3 col-md-6">
        <div class="top-title center">
          <button class="btn btn-success" style="width: 100%;margin-top: 10px;" (click)="connexion()">Connexion</button>
        </div>
      </div>
    </div>
  </div>
</section>
