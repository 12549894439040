import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {GlobalService} from "../../services/global.service";

@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.css']
})
export class SmsComponent implements OnInit {

  nums = {
    attente: 0,
    fait: 0
  }
  constructor(private route: ActivatedRoute,
              private httpClient: HttpClient,
              private globalService: GlobalService,
              private router: Router) { }

  ngOnInit(): void {
    if (localStorage.getItem("isAdmin") != "true") {
      this.router.navigate(['/suivi']);
    }
    this.getNum(0);
    this.getNum(1);
  }
  supprimer(number: number) {
    this.httpClient
      .delete<any>(this.globalService.url + '/sms/'+number, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          this.getNum(number);
        }
      }, () => {
      });
  }

  exporter(number: number): void {
    this.httpClient
      .get<any>(this.globalService.url + '/sms/export/' + number, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          window.open(this.globalService.url + '/documents/excel/' + response.filename + '.xlsx');
        } else {
          alert('Erreur lors de la modification');
        }
      }, () => {
      });
  }

  getNum(num: number): void {
    this.httpClient
      .get<any>(this.globalService.url + '/sms/num/' + num, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          if (num == 0) {
            this.nums.attente = response.num;
          } else {
            this.nums.fait = response.num;
          }
        }
      }, () => {
      });
  }
}
