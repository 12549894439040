<div class="row" style="width: 100%;margin: auto;">
  <div class="col-xl-3 col-xs-3">
    <app-menu></app-menu>
  </div>
  <div class="col-xl-9 col-xs-9" style="margin-top: 20px;">
    <div class="page-title">
      <h3 class="breadcrumb-header">Logs</h3>
    </div>
    <div id="main-wrapper">
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-white">
            <div class="panel-body">
              <div class="table-responsive">
                <table class="display table dataTable">
                  <tr>
                    <td>
                      Date
                    </td>
                    <td>
                      Catégorie
                    </td>
                    <td>
                      Texte
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <select (change)="chooseCategorie($event)" class="form-control">
                        <option>Choisir une catégorie spécifique...</option>
                        <option *ngFor="let c of categories" [value]="c">{{c}}</option>
                      </select>
                    </td>
                  </tr>
                  <tr *ngFor="let l of logs">
                    <td>
                      {{l.date * 1000 | date: 'dd/MM/yyyy HH:mm:ss'}}
                    </td>
                    <td>
                      {{l.categorie}}
                    </td>
                    <td>
                      {{l.texte}}
                    </td>
                  </tr>
                </table>
                <div class="pagination">
                  <a (click)="getLogs(currentPage - 1)" [class.disabled]="currentPage === 1">&laquo;</a>
                  <a *ngFor="let page of pages" (click)="getLogs(page)" [class.active]="page === currentPage">{{ page }}</a>
                  <a (click)="getLogs(currentPage + 1)" [class.disabled]="currentPage === totalPages">&raquo;</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
