import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {GlobalService} from "../../services/global.service";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  users: any = [];
  user = {
    identifiant: '',
    password: '',
    typeAccount: '',
    activatedStop: false
  }
  credit = 0;
  constructor(private route: ActivatedRoute,
              private httpClient: HttpClient,
              private globalService: GlobalService,
              private router: Router) { }

  ngOnInit(): void {
    if (localStorage.getItem("isAdmin") != "true") {
      this.router.navigate(['/suivi']);
    }
    this.getUsers();
  }

  addUser(): void {
    this.httpClient
      .put<any>(this.globalService.url + '/users', this.user, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          this.getUsers();
        }
      }, () => {
      });
  }
  getUsers(): void {
    this.httpClient
      .get<any>(this.globalService.url + '/users', {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          this.users = response.personnels;
          for (let u of this.users) {
            u.nouveauCredit = 0;
          }
          this.calculTotal();
        }
      }, () => {
      });
  }
  updateCredit(id: string): void {
    for (let u of this.users) {
      if (u._id == id) {
        this.httpClient
          .post<any>(this.globalService.url + '/users/credit', {id: id, credit: u.nouveauCredit}, {headers: this.globalService.headers()})
          .subscribe((response) => {
            if (response.status) {
              alert('Modifié');
              this.getUsers();
            }
          }, () => {
          });
      }
    }
  }
  updateCalculation(id: string): void {
    for (let u of this.users) {
      if (u._id == id) {
        let calculation = !u.calculation;
        u.calculation = calculation;
        this.httpClient
          .post<any>(this.globalService.url + '/users/calculation', {id: id, calculation: calculation})
          .subscribe((response) => {
            if (response.status) {
              this.getUsers();
            }
          }, () => {
          });
      }
    }
  }
  calculTotal(): void {
    this.credit = 0;
    for (let u of this.users) {
      if (u.calculation) {
        this.credit += u.credit;
      }
    }
  }
  updateActivatedStop(id: string): void {
    for (let u of this.users) {
      if (u._id == id) {
        this.httpClient
          .post<any>(this.globalService.url + '/users/activatedStop', {id: id, activatedStop: u.activatedStop})
          .subscribe((response) => {
            if (response.status) {
              this.getUsers();
            }
          }, () => {
          });
      }
    }
  }
  deleteUsers(id: string): void {
    this.httpClient
      .delete<any>(this.globalService.url + '/users/' + id, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          this.getUsers();
        }
      }, () => {
      });
  }
}
