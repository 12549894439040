import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {GlobalService} from "../../services/global.service";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-modifier-campagne',
  templateUrl: './modifier-campagne.component.html',
  styleUrls: ['./modifier-campagne.component.css']
})
export class ModifierCampagneComponent implements OnInit {

  campagne: any = {};
  idCampagne = "";
  isAdmin = false;
  error = {
    reference: false,
    message: false
  };
  constructor(private route: ActivatedRoute,
              private httpClient: HttpClient,
              private globalService: GlobalService,
              private router: Router) { }

  ngOnInit(): void {
    if (localStorage.getItem('isAdmin') == 'true') {
      this.isAdmin = true;
    }
    if (this.route.snapshot.params.idCampagne !== undefined && this.route.snapshot.params.idCampagne !== null) {
      this.idCampagne = this.route.snapshot.params.idCampagne;
      this.get();
    }
  }
  get(): void {
    this.httpClient
      .get<any>(this.globalService.url + '/campagnes/one/' + this.idCampagne)
      .subscribe((response) => {
        if (response.status) {
          this.campagne = response.campagne;
        }
      }, () => {
      });
  }
  update(): void {
    this.httpClient
      .post<any>(this.globalService.url + '/campagnes', this.campagne, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          this.router.navigate(['/suivi']);
        }
      }, () => {
      });
  }
  confirmationModificationCampagne(): void {
    this.error.reference = false;
    this.error.message = false;
    if (this.campagne.nomCampagne != "" && this.campagne.message != "") {
      this.update();
    } else {
      if (this.campagne.nomCampagne == "") {
        this.error.reference = true;
      }
      if (this.campagne.message == "") {
        this.error.message = true;
      }
    }
  }
}
