import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {GlobalService} from "../../services/global.service";

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})
export class LogsComponent implements OnInit {

  logs: any = [];
  total = 100;
  currentPage = 0;
  categories: any = [];
  categorie = '';
  totalPages = 0;
  pages: number[] = [];

  constructor(private route: ActivatedRoute,
              private httpClient: HttpClient,
              private globalService: GlobalService,
              private router: Router) { }

  ngOnInit(): void {
    if (localStorage.getItem("isAdmin") != "true") {
      this.router.navigate(['/suivi']);
    }
    this.getLogs(0);
    this.getCategories();
  }
  getLogs(page: number): void {
    this.httpClient
      .post<any>(this.globalService.url + '/logs', {categorie: this.categorie, page: page}, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          this.logs = response.logs;
          this.total = response.total;

          this.totalPages = Math.ceil(this.total / 100);
          this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
        }
      }, () => {
      });
  }
  getCategories(): void {
    this.httpClient
      .get<any>(this.globalService.url + '/logs/categorie', {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          this.categories = response.categories;
        }
      }, () => {
      });
  }
  chooseCategorie(event: any): void {
    this.categorie = event.target.value;
    this.getLogs(0);
  }
}
