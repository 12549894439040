<div class="row" style="width: 100%;margin: auto;">
  <div class="col-xl-2 col-xs-2">
    <app-menu></app-menu>
  </div>
  <div class="col-xl-10 col-xs-10" style="margin-top: 20px;">
    <div class="page-title">
      <h3 class="breadcrumb-header">Page inexistante</h3>
    </div>
  </div>
</div>
