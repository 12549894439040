import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {GlobalService} from "../../services/global.service";

@Component({
  selector: 'app-credit-disponible',
  templateUrl: './credit-disponible.component.html',
  styleUrls: ['./credit-disponible.component.css']
})
export class CreditDisponibleComponent implements OnInit {

  userId = "";
  credit = 0;
  constructor(private route: ActivatedRoute,
              private httpClient: HttpClient,
              private globalService: GlobalService) { }

  ngOnInit(): void {
    this.userId = String(localStorage.getItem("id"));
    this.getCredit();
  }
  getCredit(): void {
    this.httpClient
      .get<any>(this.globalService.url + '/users/' + this.userId, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          this.credit = response.personnel.credit;
        }
      }, () => {
      });
  }
}
