import { Component, OnInit } from '@angular/core';

import {
  faBars, faTimes,
} from '@fortawesome/free-solid-svg-icons';

import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from '../../services/global.service';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css',
    './../../../assets/css/site/style.css']
})
export class MenuComponent implements OnInit {
  icon = faBars;

  url = '';
  open = false;
  isAdmin = false;
  idUtilisateur: string | null = '';
  constructor(private route: ActivatedRoute,
              private httpClient: HttpClient,
              private globalService: GlobalService,
              private router: Router) { }

  ngOnInit(): void {
    if (localStorage.getItem('isAdmin') == 'true') {
      this.isAdmin = true;
    }
    this.idUtilisateur = localStorage.getItem('id');
    const id = localStorage.getItem('id') !== undefined && localStorage.getItem('id') !== null;
    const token = localStorage.getItem('token') !== undefined && localStorage.getItem('token') !== null;
    // @ts-ignore
    if (!id || !token) {
      localStorage.clear();
      this.router.navigate(['/']);
    }
    const url = window.location.href;
    if (url.split('ports').length > 1) {
      this.url = 'ports';
    } else if (url.split('campagne').length > 1) {
      this.url = 'campagne';
    } else if (url.split('parametrage').length > 1) {
      this.url = 'parametrage';
    } else if (url.split('sms').length > 1) {
      this.url = 'sms';
    } else if (url.split('users').length > 1) {
      this.url = 'users';
    } else if (url.split('suivi').length > 1) {
      this.url = 'suivi';
    } else if (url.split('archive').length > 1) {
      this.url = 'archive';
    } else if (url.split('sim-management').length > 1) {
      this.url = 'sim-management';
    } else if (url.split('logs').length > 1) {
      this.url = 'logs';
    }
  }
  deconnexion(): void {
    localStorage.clear();
    this.router.navigate(['/']);
  }
  menu(): void {
    this.open = !this.open;
    if (this.open) {
      this.icon = faTimes;
    } else {
      this.icon = faBars;
    }
  }
}
